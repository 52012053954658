<template>
  <div class="information-download">
    <div class="file-list">
      文件： <span
        v-for="(file, index) in downloadForm.fileList"
        :key="index"
        type="info"
      >
        {{ file.wjm }}
        <span style="color: red;" v-if="!isDateWithinValidityPeriod(downloadForm.fssj, downloadForm.wjsxx)">
          (已过期)
        </span>
      </span>
    </div>
     
    <template v-if="isDateWithinValidityPeriod(downloadForm.fssj, downloadForm.wjsxx)">
      <div v-if="phoneCodeShow">
        <div class="item-box">
          <span class="blue-font font-width">验证码</span>
          <input
            type="text"
            placeholder="请输入算式验证码"
            v-model="verification"
            class="modal-input"
          />
          <img
            :src="imgCode.img"
            class="check-img"
            style="cursor: pointer;top: 2px;"
            @click="getImageCode"
          />
        </div>
        <div class="w-100 mt-2">
          <el-button type="primary" @click="getCode">获取验证码</el-button>
          <el-button type="info" @click="phoneCodeShow = false;">返回</el-button>
        </div>
      </div>
      <div v-else>
        <div class="item-box">
          <span class="blue-font font-width " style="cursor: pointer;min-width: 120px;" @click="isCodeExist">点击获取验证码</span>
          <input
            type="text"
            placeholder="请输入短信验证码"
            v-model="phoneCode"
            class="modal-input"
            required
          />
        </div>
        <div class="w-100 mt-2">
          <el-button type="primary" @click="openFile">下载文件</el-button>
        </div>
      </div>
    </template>
      
  </div>
</template>

<script>
import {
  info,
  logAdd
} from "@/api/admin/information/informationCollaboration.js";
import {
  getcode,
  userCodeExpired,
  sendUserPhoneCode
} from "@/api/index.js";
import $ from "jquery";
import { watch } from "vue";
export default {
  name: 'InformationDownload',
  props: {
    infoId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
        downloadForm: {},
        dialogVisible: false,
        wjdz: '',
        phoneCodeShow: false,
        phoneCode: '',
        verification: '',
        imgCode: {
          img: '',
          uuid: ''
        }
    }
  },
  methods: {
    getInfo() {
      this.phoneCode = ''
      info(this.infoId).then(res => {
        if(res.status) {
          this.downloadForm = res.data;
        }
      });
    },
    open(file) {
      this.wjdz = file
      this.dialogVisible = true
      this.phoneCodeShow = false
      this.phoneCode = ''
      this.verification = ''
    },
    openFile() {
      if(!this.phoneCode) {
        this.$notify({
          message: '请输入短信验证码',
          type: 'warning'
        });
        return;
      }
      // 下载记录
      let param = {
        phoneCode: this.phoneCode,
        infoId: this.downloadForm.infoId,
        fileId: this.downloadForm.fileList[0].fileId
      };
      logAdd(param).then(res => {
        if(res.status) {
          window.open(this.getFileUrl(this.downloadForm.fileList[0].wjdz, false, this.phoneCode), "_blank");
        }
      });
    },
    // 验证码是否过期
    isCodeExist() {
      userCodeExpired().then((res) => {
        if (res.status) {
          if (res.data) { //已过期可重发
            this.getImageCode();
            this.phoneCodeShow = true;
          } else {
            this.$confirm("验证码尚未过期, 是否重新获取?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
                .then(() => {
                  this.getImageCode();
                  this.phoneCodeShow = true;
                })
                .catch(() => {
                  return;
                });
          }
        }
      });
    },
    // 获取算式验证码
    getImageCode: function() {
      var _this = this;
      
      getcode().then((res) => {
        if (res.status) {
          _this.imgCode.uuid = res.data.uuid;
          _this.imgCode.img = res.data.img;
        }
      });
    },
    /* 发送手机验证码*/
    sendPhoneCode: function(formDate) {
      return sendUserPhoneCode(formDate);
    },

    /*获取验证码*/
    getCode: function() {
      var _this = this
      if(!this.verification) {
        return;
        }
      if (!this.committingPhoneCode) {
        /*如果状态正确*/
        var formDate = {
          code: this.verification,
          uuid: this.imgCode.uuid
        }
        this.committingPhoneCode = true
        this.sendPhoneCode(formDate).then(res => {
          if (res.status) {
            _this.resetCode(60); //倒计时
            this.committingPhoneCode = false
            this.phoneCodeShow = false;
            _this.$notify({
              title: '成功',
              message: res.message,
              type: 'success'
            });
          } else {
            // this.imgCodeChange();
            _this.resetCode(5);
          }

        })
      }

    },
    //倒计时
    resetCode: function(second) {
      var timer = null;
      var _this = this;
      timer = setInterval(function() {
        second -= 1;
        if (second > 0) {
          $('.get-code').css('cursor', 'no-drop')
          $('.get-code').text(second + '秒')
        } else {
          clearInterval(timer);
          $('.get-code').css('cursor', 'pointer')
          $('.get-code').text('点击获取验证码')
          _this.committingPhoneCode = false
        }
      }, 1000);
    },
    
    isDateWithinValidityPeriod(dateStr, daysToAdd) {
      if(!dateStr) {
        return;
      }
      // 将字符串转换为 Date 对象
      const startDate = new Date(dateStr);
      // 检查日期字符串是否有效
      if (isNaN(startDate.getTime())) {
          throw new Error('Invalid date string');
      }
      // 加上指定的天数
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + daysToAdd);
      
      // 获取当前日期
      const currentDate = new Date();
      
      // 判断当前日期是否在有效期内
      const isValid = currentDate <= endDate;
      
      return isValid;
    }
  },
  mounted() {
    this.getInfo();
  },
  watch: {
    infoId(newVal, oldVal) {
      this.phoneCode = ''
      this.infoId = newVal;
      this.getInfo();
    }
  } 
}
</script>
<style type="text/css">
@import "~@/assets/css/account.css";
</style>

<style scoped>
.information-download {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

.file-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;
}

.file-tag {
  background-color: #fafafa;
  border: 1px solid #dbdbdb;
  color: #262626;
  padding: 8px 12px;
  border-radius: 3px;
  font-size: 14px;
  transition: background-color 0.3s;
}

.file-tag:hover {
  background-color: #efefef;
}

.item-box {
  margin-bottom: 20px;
  position: relative;
}
 

.modal-input {
  width: 100%;
  padding: 9px 40px 9px 8px; /* 增加右侧内边距以容纳图片 */
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  font-size: 14px;
}
 

/* 移除深度选择器,因为在Vue 3中不再需要 */
.v-modal {
  z-index: 9998 !important;
}
</style>
